@use '/src/styles/constants/colors';
@use '/src/styles/constants/sizes';

.mainImage {
  height: calc(100dvh - sizes.$GNB_HEIGHT_M - 80px);
  background-image: url('/assets/images/IMG_0433.jpg');
  background-position: center;
  background-size: cover;
}

.bottomSheet {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: sizes.$MAX_WIDTH;
  padding: 26px 20px;
  text-align: center;
  background: linear-gradient(rgba(255, 255, 255, 60%) 10%, rgba(255, 255, 255, 100%) 50%);

  :first-child {
    font-size: 16px;
    font-weight: 400;
    color: colors.$GRAY3;

    span {
      font-size: 20px;
      font-weight: 700;
      color: colors.$GRAY1;
    }
  }

  p {
    margin-top: 12px;
    margin-bottom: 20px;
    line-height: 1.5;
    color: colors.$GRAY1;
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 700ms ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
