@use '/src/styles/constants/colors';
@use '/src/styles/constants/sizes';

.button {
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  border-radius: sizes.$BORDER_RADIUS;
  transition: all 0.2s ease-in-out;

  &.fullWidth {
    width: 100%;
  }

  &.large {
    width: 100px;
  }

  &.medium {
    width: 80px;
  }

  &.small {
    width: 50px;
  }

  &.primary {
    color: colors.$WHITE;
    background-color: colors.$PRIMARY;

    &:hover,
    &:active {
      background-color: colors.$PRIMARY_PRESSED;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  &.secondary {
    color: colors.$PRIMARY;
    border: 1.5px solid colors.$PRIMARY;

    &:hover,
    &:active {
      color: colors.$PRIMARY_PRESSED;
      border-color: colors.$PRIMARY_PRESSED;
    }

    &:disabled {
      background-color: colors.$GRAYE;
      opacity: 0.7;
    }
  }

  &.ghost {
    color: colors.$PRIMARY;

    &:hover,
    &:active {
      color: colors.$PRIMARY_PRESSED;
    }

    &:disabled {
      background-color: colors.$GRAYE;
      opacity: 0.7;
    }
  }
}
