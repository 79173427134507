@use '/src/styles/constants/colors';

.section {
  padding: 14px 35px 35px;
  background-color: colors.$SECONDARY;

  .date {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: colors.$GRAY1;
    text-align: center;
  }

  .calendar {
    padding: 20px 0;
    color: colors.$GRAY1;
    text-align: center;
    background-color: colors.$WHITE;
  }

  .month {
    margin-top: 4px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
  }

  table {
    margin: 0 auto;
  }

  .selected {
    font-weight: 700;
    color: colors.$WHITE;
    background-color: colors.$PRIMARY;
    border-radius: 50%;
  }
}
