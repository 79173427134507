@use '/src/styles/constants/colors';

.map {
  width: 100%;
  height: 200px;
  margin-top: 16px;
}

.location {
  padding: 20px;
  line-height: 1.5;
  color: colors.$GRAY1;
  text-align: center;

  h3 {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 700;
  }

  h4 {
    margin-bottom: 4px;
    font-weight: 700;
  }

  p {
    white-space: pre-line;
  }

  .addressItem {
    margin-top: 20px;
  }
}

.mapIcons {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 28px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 10%);
  }
}
