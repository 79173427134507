@use '/src/styles/constants/colors';

.imageList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;

  li {
    overflow: hidden;
    border-radius: 8px;

    button {
      width: 100%;
      height: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      aspect-ratio: 3/4;
      background-position: center;
      background-size: cover;
    }
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed colors.$PRIMARY;
    border-radius: 8px;

    img {
      width: 100px;
      height: auto;
      object-fit: contain;
    }
  }
}
