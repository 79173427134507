@use '/src/styles/constants/colors';
@use '/src/styles/constants/levels';
@use '/src/styles/constants/sizes';

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: levels.$MODAL;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 70%);
  backdrop-filter: blur(10px);

  .viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: calc(sizes.$MAX_WIDTH + 10px);
    height: 100%;
    max-height: 900px;
    background-color: colors.$WHITE;
  }

  .image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 16px;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    color: colors.$GRAY1;

    & > div {
      padding: 8px 16px;
      color: colors.$WHITE;
      background-color: colors.$BLACK;
      border-radius: 16px;
      opacity: 0.5;
    }

    button {
      padding: 8px;
      margin: 0 10px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      fill: colors.$GRAY9;
    }
  }
}
